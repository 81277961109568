<template>
  <div class="login">
    <div class="left">
      <img class="loginImg" src="../assets/img/login/login.png" alt="" />
      <div class="desc">
        <h1 class="title">飓风高性能车库管理系统</h1>
        <p>####################################</p>
        <p>
          ######################################################！！！
        </p>
        <p>
          ######################################################################
        </p>
        <p>
          1）######################################################################
        </p>
        <p>2）######################################################################</p>
        <p>3）######################################################################</p>
        <p>4）######################################################################</p>
      </div>
    </div>
    <div class="right">
      <div class="operationBox">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
          <el-form-item prop="name" class="passs" label-width="100px">
            <span slot="label">
              <i class="el-icon-user"></i>
              用户名
            </span>
            <el-input v-model="ruleForm.name" clearable></el-input>
          </el-form-item>
          <el-form-item prop="pass" class="passs" label-width="100px">
            <span slot="label">
              <i class="el-icon-lock"></i>
              密码
            </span>
            <el-input v-model="ruleForm.pass" type="password" clearable></el-input>
          </el-form-item>
          <el-form-item style="margin-top: 100px">
            <el-button class="btn" type="primary" @click="submitForm('ruleForm')">登录
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import { apiLogin } from "@/http/api";
export default {
  name: "login",
  data () {
    return {
      ruleForm: {
        name: "",
        pass: "",
      },
      rules: {
        name: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        pass: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  mounted () {
    var that = this;
    document.onkeydown = function () {
      var key = window.event.keyCode;
      if (key == 13) {
        that.submitForm("ruleForm");
      }
    };
  },
  methods: {
    submitForm (formName) {
      var that = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log("submit!");
          apiLogin({
            userName: this.ruleForm.name,
            password: this.ruleForm.pass,
          })
            .then((res) => {
              sessionStorage.setItem("token", res.data.token);
              sessionStorage.setItem("name", res.data.userName);
              sessionStorage.setItem("urlshow", "homePage")
              that.$message({
                message: "登录成功",
                type: "success",
              });
              that.$router.push({ path: "/" });
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          this.$message.error('请检查用户名和密码是否输入或是否输入正确');
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
.login {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url(../assets/img/login/login.jpg);
  display: flex;
  justify-content: space-between;
  padding: 0 3%;
}
.left {
  flex: 1;
  position: relative;
}
.right {
  width: 50%;
  height: 100%;
  position: relative;
}
.loginImg {
  width: 30%;
  position: absolute;
  top: 35px;
  left: 0;
}
.operationBox {
  width: 50%;
  height: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25% 0 0 -25%;
  background: #fff;
  box-shadow: 10px 10px 20px 10px #ccc;
  border-radius: 4px;
}
.demo-ruleForm {
  margin: 100px auto;
}
.el-input {
  width: 80%;
}
.btn {
  width: 80%;
}
.desc {
  width: 100%;
  height: 80%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.title {
  width: 100%;
  text-align: left;
  font-size: 36px;
  line-height: 90px;
}
p {
  width: 100%;
  text-align: left;
  font-size: 16px;
  line-height: 40px;
}
</style>